import React from 'react'
import Input from "../components/Input";
import BottomNavigation from "../components/BottomNavigation";
import { useEffect, useState } from "react";
import Checkbox from "../components/Checkbox";
import Helper from "../components/Helper";
import { useClient } from "../contexts/LivretMainContext";
import { validate, validateMandatory } from '../services/ValidateStep2Service';

const Step2: React.FC= () => {
  const {client,setClient, setSubStep,mainErrors,setMainErrors} = useClient();
  const [canProceed, setCanProceed] = useState(false);

  useEffect(()=>{
    var validMandatory= validateMandatory(client)
    var isValid= Object.values(mainErrors).every(x => x === ""); 
    setCanProceed(!(isValid && validMandatory))
  },[mainErrors]);
 
  useEffect(()=>{
    var validMandatory= validateMandatory(client)
    var validateModel= validate(client);
    setCanProceed(validateModel.isValid && !validMandatory);
    setMainErrors(validateModel.errors);
  },[client])

  useEffect(() => {
    setSubStep(2)
  }, [setSubStep]);
return (
      <>
        <div className="main">
          <div className="form">
            <h2>Entrons en contact&nbsp;!</h2>            
            <div className='mb-4'>
              <Helper desktopOnly>
                <p>Grâce à votre adresse email, vous recevrez un lien qui vous permettra de reprendre vos démarches à tout moment.</p>
              </Helper>
            </div>
            <div className="form-content">
            <Input
                  name="lC_EMAIL"
                  error={mainErrors.EmailValidator || !client.lC_EMAIL ? true : false}
                  errorMsg={mainErrors.EmailValidator }
                  valid={mainErrors.EmailValidator || !client.lC_EMAIL ? false : true}
                  onChange={(e) => setClient({ ...client, lC_EMAIL: e.target.value })}
                  label="Adresse email"
                  required
                  onCopy = {true}
                  onPaste = {true}
                  value={client.lC_EMAIL}
                  disabled={client.isindicationmode
                  }
                />
                <Input
                  name="lC_CONFIRMATION_EMAIL"
                  error={mainErrors.ConfirmationEmailValidator? true : false}
                  errorMsg={mainErrors.ConfirmationEmailValidator}
                  valid={mainErrors.ConfirmationEmailValidator? false : true}
                  onChange={(e) => setClient({ ...client, lC_CONFIRMATION_EMAIL: e.target.value })}
                  label="Confirmation de l'adresse email"
                  required
                  onCopy = {true}
                  onPaste = {true}
                  value={client.lC_CONFIRMATION_EMAIL}
                />
                <Helper desktopOnly>
                  <p>Votre numéro de téléphone sera utilisé pour la signature électronique de votre Contrat.</p>
                </Helper>   
                <Input
                  name="lC_TELEPHONE"
                  error={mainErrors.PhoneNumberValidator || !client.lC_TELEPHONE ? true : false}
                  errorMsg={mainErrors.PhoneNumberValidator}
                  valid={mainErrors.PhoneNumberValidator || !client.lC_TELEPHONE ? false : true}
                  onChange={(e) => setClient({ ...client, lC_TELEPHONE: e.target.value })}
                  label="Numéro de téléphone portable"
                  required
                  value={client.lC_TELEPHONE}
                />
              <Checkbox
                name="communication"
                onChange={(e) => setClient({ ...client, lC_COMMUNIC_COMM_OK: e.target.checked })}
                checked={client.lC_COMMUNIC_COMM_OK}
                text="J'accepte de recevoir des communications commerciales par e-mail concernant les produits de la Banque Française Mutualiste"
              />
            </div>
            <div className='text-white mt-6'><span className="required">*</span> Champ obligatoire </div>
          </div>
        </div>
        <div className="helper-container">          
        </div>
        <BottomNavigation
          prevDisabled= {false}
          nextDisabled={canProceed}          
          prev='/step1'
          next="/step3"
        />
      </>
  );
};

export default Step2;