import { useContext, useEffect, useState } from 'react';
import BottomNavigation from '../components/BottomNavigation'
import Input from '../components/Input'
import Select from '../components/Select'
import { useClient } from "../contexts/LivretMainContext";
import { validate, validateMandatory } from '../services/ValidateStep5Service';
import { countries } from '../components/types/countries';
import axiosInstance from '../Interceptors/axiosconfig';
interface formErrors {
  payeNaissance?: string,
  codePostale?: string,
  ville?: string,
  nationalite?: string,
}

const Step4: React.FC = () => {
  const [optionsList, setOptionsList] = useState([])
  const { client, setClient, setSubStep, mainErrors, setMainErrors } = useClient();
  const [currentPaysNaissance, setCurrentPaysNaissance] = useState(client.lC_PAYS_NAISSANCE);
  const [canProceed, setCanProceed] = useState(false);
  const onChange = async (e: any) => {
    setClient({ ...client, lC_CP_NAISSANCE: e.target.value });
    var filtredOptions = [];
    if (e.target.value.length > 1) {
      const response = await axiosInstance.get(`/Ville/SearchVilleByDepartement?search=` + e.target.value)
      filtredOptions = response.data.map((x: { pcI_LIBELLE_AFFICHE: any; pcI_INSEE: any }) => ({
        label: x.pcI_LIBELLE_AFFICHE,
        value: x.pcI_INSEE,
        labelCity: x.pcI_LIBELLE_AFFICHE
      }))
      setOptionsList(filtredOptions);
    } else {
      setOptionsList([]);
    }
  };
  const OnClickHandle = (selectedOpt: any) => {
    setClient({ ...client, lC_CP_NAISSANCE: selectedOpt.value, lC_VILLE_NAISSANCE: selectedOpt.labelCity })
  }

  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var isValid = Object.values(mainErrors).every(x => x === "");
    setCanProceed(!(isValid && validMandatory))
  }, [mainErrors]);

  useEffect(() => {
    if(client.lC_PAYS_NAISSANCE !== currentPaysNaissance){
      setClient({ ...client, lC_CP_NAISSANCE: "", lC_VILLE_NAISSANCE: "" });
      setCurrentPaysNaissance(client.lC_PAYS_NAISSANCE);
    }
  }, [client.lC_PAYS_NAISSANCE]);

  useEffect(() => {
    var validMandatory = validateMandatory(client)
    var validateModel = validate(client);
    setCanProceed(validateModel.isValid && !validMandatory);
    setMainErrors(validateModel.errors);
  }, [client])

  useEffect(() => {
    setSubStep(4)
  }, [setSubStep]);

  return (
    <>
      <div className="main">
        <div className="form">
          <h2>Où êtes-vous né(e)&nbsp;?</h2>
          <div className="form-content">
            <Select
              name="lC_PAYS_NAISSANCE"
              error={mainErrors.BirthCountryValidator ? true : false}
              errorMsg={mainErrors.BirthCountryValidator}
              valid={mainErrors.BirthCountryValidator || !client.lC_PAYS_NAISSANCE ? false : true}
              onChange={(e) => setClient({ ...client, lC_PAYS_NAISSANCE: e.target.value })}
              label="Pays de naissance"
              value={client.lC_PAYS_NAISSANCE}
              required
            >
              <option value=""></option>
              {Object.entries(countries).map(([index, obj]) => <option value={obj.code} key={index}>{obj.name}</option>)}
            </Select>
            <div className="flex flex-row gap-8">
              <Input
                name="lC_CP_NAISSANCE"
                error={mainErrors.BirthZipCodeValidator ? true : false}
                errorMsg={mainErrors.BirthZipCodeValidator}
                valid={mainErrors.BirthZipCodeValidator || !client.lC_CP_NAISSANCE ? false : true}
                // onChange={(e) => setClient({ ...client, lC_CP_NAISSANCE: e.target.value })}
                onChange={(e) => onChange(e)}
                OnClick={OnClickHandle}
                label="Dept. naissance"
                required={client.lC_PAYS_NAISSANCE === 'FR'}
                optionsList={optionsList}
                IsAutocomplete={true}
                value={client.lC_CP_NAISSANCE.slice(0, 2)}
                className="row-4 row-sm-5"
                maxLength='5'
                disabled={client.lC_PAYS_NAISSANCE !== 'FR' && client.lC_PAYS_NAISSANCE !== ""}
              />

              <Input
                name="lC_VILLE_NAISSANCE"
                error={mainErrors.BirthCityValidator ? true : false}
                errorMsg={mainErrors.BirthCityValidator}
                valid={mainErrors.BirthCityValidator || !client.lC_VILLE_NAISSANCE ? false : true}
                onChange={(e) => setClient({ ...client, lC_VILLE_NAISSANCE: e.target.value })}
                label="Ville de naissance"
                required
                value={client.lC_VILLE_NAISSANCE}
                className="row-8 row-sm-7"
              />
            </div>
            <Select
              name="lC_NATIONALITE"
              error={mainErrors.NationalityValidator ? true : false}
              errorMsg={mainErrors.NationalityValidator}
              valid={mainErrors.NationalityValidator || !client.lC_NATIONALITE ? false : true}
              onChange={(e) => setClient({ ...client, lC_NATIONALITE: e.target.value })}
              label="Nationalité"
              value={client.lC_NATIONALITE}
              required
            >
             
             <option value=""></option>
              {Object.entries(countries).map(([index, obj]) => <option value={obj.code} key={index}>{obj.nationality}</option>)}
            </Select>
          </div>
          <div className='text-white mt-6'><span className="required">*</span> Champ obligatoire </div>
        </div>
      </div>
      <div className="helper-container">
      </div>
      <BottomNavigation
        prevDisabled={false}
        nextDisabled={canProceed}
        prev="/step3"
        next="/step5"
      />
    </>
  )
}

export default Step4
