import { useNavigate } from "react-router-dom";
import { Client } from "./../types/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import Problem from "../types/problem";
import { useClient } from "../contexts/LivretMainContext";
import { FormsErros } from "../types/FormsErros";
import { paramFooterResponse } from "../types/paramFooter";
import axiosInstance from "../Interceptors/axiosconfig";

const useGetMunicipalities = (value: string) => {
  return useQuery<Client, AxiosError>({
    queryFn: async () =>
      await axiosInstance.get(`/Hexapost/SearchMunicipalitiesByValue?search=` + value).then((resp) => resp.data)
  });
};
const useGetVilles = (value: string) => {
  return useQuery<Client, AxiosError>({
    queryFn: async () =>
      await axiosInstance.get(`/Ville/SearchVilleByDepartement?search=` + value).then((resp) => resp.data)
  });
};


const useUpdateClient = (next: string) => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  const { setMainErrors } = useClient()
  return useMutation<AxiosResponse, AxiosError<Problem>, Client>({
    mutationFn: (h) => axiosInstance.post(`/client/UpdateClient`, h),
    onSuccess: (_, client) => {
      queryClient.refetchQueries({ queryKey: ["clients"] });
      nav(next);
    },
    onError: (errors) => {
      var errorMessages: any = {}
      var erros: any = errors.response?.data;
      erros.map((er: { code: keyof FormsErros, message: string }, index: number) => (
        errorMessages[er.code] = er.message
      ));
      setMainErrors(errorMessages);
    }
  });
};

const useAddClient = (next: string) => {
  const { client, setClient, setMainErrors } = useClient()
  const queryClient = useQueryClient();
  const nav = useNavigate();
  //AxiosResponse response of api
  //AxiosError error type
  //Client type that we want to send to to api in request body 
  return useMutation<AxiosResponse, AxiosError<Problem>, Client>(
    (h) => axiosInstance.post(`/Client/CreateClient`, h),
    {
      onSuccess: (_, c) => {
        setClient({ ...client, lC_GUID: _.data });
        queryClient.invalidateQueries("Clients");
        nav(next);
      },
      onError: (errors) => {
        var errorMessages: any = {}
        var erros: any = errors.response?.data;
        erros.map((er: { code: keyof FormsErros, message: string }, index: number) => (
          errorMessages[er.code] = er.message
        ));
        setMainErrors(errorMessages);
      }
    }
  );
};

const useGetParamFooter = () => {
  return useQuery<Array<paramFooterResponse>, AxiosError>({
    queryFn: async () =>
      await axiosInstance.get(`/Param/GetParamFooter`).then((resp) => resp.data)
  });
};

export {
  useAddClient,
  useUpdateClient,
  useGetMunicipalities,
  useGetVilles,
  useGetParamFooter
};