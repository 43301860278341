import { useEffect, useRef, useState } from 'react'
import { FileUpload, ItemTemplateOptions } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { UploadType } from '../interface'
import axiosInstance from '../Interceptors/axiosconfig'
import { useClient } from '../contexts/LivretMainContext'
import { documentsErrors } from '../types/documents'

function Upload({
  label,
  infoText = '',
  required = false,
  documentType = '',
  children,
  documentsNumber,
  documentCode = '',
  documents,
  documentsErrors
}: UploadType) {
  const uploadRef1 = useRef<FileUpload>(null);
  const uploadRef2 = useRef<FileUpload>(null);
  const [filesNumber, setFilesNumber] = useState(documentsNumber);
  const [fileType, setFileType] = useState(documentType);
  const [errors, setErrors] = useState(documentsErrors)
  const { client, setClient } = useClient();
  const [documentErrorMessage, setDocumentErrorMessage] = useState("");
  const [documentMessage, setDocumentMessage] = useState("");

  const clickUploadButton = () => {
    if (uploadRef1.current && documentsNumber == 1) {
      uploadRef1.current.getInput().click()
    }
  }

  const RemoveFile = (event: any, options: ItemTemplateOptions, fileId: number, name: string) => {
    if (documents) {
      var code = documentsNumber == 2 ? (documentCode == "TI-IDENTITE" ? (fileId == 1 ? "TI-IDENTITE-R" : "TI-IDENTITE-V") : (fileId == 1 ? "HE-IDENTITE-R" : "HE-IDENTITE-V")) : documentCode;
      var refAdg = documents.find(x => x.lcD_NOM == name && x.lcD_DOCUMENT_CODE == code) ? documents.filter(x => x.lcD_NOM == name && x.lcD_DOCUMENT_CODE == code)[0].lcD_REF_ADG : ""
      if (refAdg) {
        axiosInstance.post(`/api/FileUpload/RemoveFile?idGed=` + decodeURI(refAdg)).then((resp) => {
          if (resp.data == true) {
            setClient((prevClient) => ({ ...prevClient, lIVRET_CLIENT_DOCUMENTS: prevClient.lIVRET_CLIENT_DOCUMENTS.filter((_, i) => _.lcD_REF_ADG !== refAdg) }))
            if (fileId == 1) {
              uploadRef1.current?.clear()
              options.onRemove(event)
            } else {
              uploadRef2.current?.clear()
              options.onRemove(event)
            }
          }
        });
      }
    }
  }

  const handleFileUpload = (event: any, index: number) => {
    if (documentsNumber == undefined || documentsNumber == null) {
      event.files = [];
      if (index == 1 && uploadRef1.current) {
        uploadRef1.current.clear();
        uploadRef1.current.setUploadedFiles([]);
      }
      if (index == 2 && uploadRef2.current) {
        uploadRef2.current.clear();
        uploadRef2.current.setUploadedFiles([]);
      }
    } else {
      setDocumentMessage("Téléchargement...");
      //supprimer les occurrences téléchargées du même document si existe
      var docsTobeRemoved = client.lIVRET_CLIENT_DOCUMENTS.filter(x => x.lC_GUID == client.lC_GUID && x.lcD_DOCUMENT_CODE == documentCode) ;
      var ids= docsTobeRemoved.map(x=>x.lcD_REF_ADG);
      var newDocs= client.lIVRET_CLIENT_DOCUMENTS.filter((_, i) => !ids.includes(_.lcD_REF_ADG))
      setClient((prevClient) => ({ ...prevClient, lIVRET_CLIENT_DOCUMENTS: newDocs }))

      setErrors([]);
      const valideTypes = ["application/pdf", "image/jpeg", "image/jpg", "image/png"]
      const selectedFile = event.files[0];
      const type = selectedFile.type;
      if (!valideTypes.includes(type)) {
        event.files = [];
        if (index == 1 && uploadRef1.current) {
          uploadRef1.current.clear();
          uploadRef1.current.setUploadedFiles([]);
        }
        if (index == 2 && uploadRef2.current) {
          uploadRef2.current.clear();
          uploadRef2.current.setUploadedFiles([]);
        }
        var docs = [];
        var doc: documentsErrors = {
          clientGuid: client.lC_GUID,
          document_Code: documentCode,
          document_Identifier: "",
          document_Type: "",
          isOk: false,
          message: "Le document n'est pas au bon format. Veuillez recommencer dans le format PDF, JPEG ou JPG.",
          ref_Adg: ""
        }
        docs.push(doc)
        setErrors(docs);
        setDocumentErrorMessage("Le document n'est pas au bon format. Veuillez recommencer dans le format PDF, JPEG ou JPG.");
      } else {
        event.files = [];
        if (index == 1 && uploadRef1.current) {
          uploadRef1.current.clear();
          uploadRef1.current.setUploadedFiles([]);
        }
        if (index == 2 && uploadRef2.current) {
          uploadRef2.current.clear();
          uploadRef2.current.setUploadedFiles([]);
        }
        const formdata = new FormData();
        formdata.append('fileDetails', selectedFile);
        formdata.append('fileType', type == 'application/pdf' ? '1' : '2');
        formdata.append('lC_GUID', client.lC_GUID);
        formdata.append('docCode', filesNumber == 1 ? documentCode : (documentCode == "TI-IDENTITE" ? (index == 1 ? "TI-IDENTITE-R" : "TI-IDENTITE-V") : (index == 1 ? "HE-IDENTITE-R" : "HE-IDENTITE-V")));
        formdata.append('docType', fileType);
        axiosInstance.post(`/api/FileUpload/PostSingleFile`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((resp) => {
          if (resp.data == false) {
            var docs = [];
            var doc: documentsErrors = {
              clientGuid: "",
              document_Code: documentCode,
              document_Identifier: "",
              document_Type: "",
              isOk: false,
              message: "Une erreur a été rencontrée sur votre justificatif, merci de vérifier votre saisie.",
              ref_Adg: ""
            }
            docs.push(doc)
            setErrors(docs);
            setDocumentErrorMessage("Une erreur a été rencontrée sur votre justificatif, merci de vérifier votre saisie.");
            setDocumentMessage("");
          } else {
            var files: File[] = [new File([], resp.data.lcD_NOM)]
            if (index == 1 && uploadRef1.current) {
              uploadRef1.current.clear();
              uploadRef1.current.setUploadedFiles(files);
            }
            if (index == 2 && uploadRef2.current) {
              uploadRef2.current.clear();
              uploadRef2.current.setUploadedFiles(files);
            }
            setClient((prevClient) => ({ ...prevClient, lIVRET_CLIENT_DOCUMENTS: [...prevClient.lIVRET_CLIENT_DOCUMENTS, resp.data] }))
            setDocumentMessage("");
          }
        });
      }
    }
  }

  useEffect(() => {
    setFilesNumber(documentsNumber);
    setDocuments()
  }, [documentsNumber]);

  useEffect(() => {
    setFileType(documentType);
  }, [documentType]);

  useEffect(() => {
    setDocuments()
  }, []);

  const setDocuments = () => {
    if (documents) {
      if (!documentsNumber || documentsNumber == 1) {
        var document = documents.find(x => x.lcD_DOCUMENT_CODE == documentCode);
        if (document) {
          var files: File[] = [new File([], document.lcD_NOM)]
          if (uploadRef1.current) {
            uploadRef1.current.clear();
            uploadRef1.current.setUploadedFiles(files);
          }
        }
      } else if (documentsNumber == 2) {
        var document1 = documents.find(x => x.lcD_DOCUMENT_CODE == (documentCode == "TI-IDENTITE" ? "TI-IDENTITE-R" : "HE-IDENTITE-R"));
        var document2 = documents.find(x => x.lcD_DOCUMENT_CODE == (documentCode == "TI-IDENTITE" ? "TI-IDENTITE-V" : "HE-IDENTITE-V"));
        if (document1) {
          var files: File[] = [new File([], document1.lcD_NOM)]
          if (uploadRef1.current) {
            uploadRef1.current.clear();
            uploadRef1.current.setUploadedFiles(files);
          }
        }
        if (document2) {
          var files2: File[] = [new File([], document2.lcD_NOM)]
          if (uploadRef2.current) {
            uploadRef2.current.clear();
            uploadRef2.current.setUploadedFiles(files2);
          }
        }
      }
    }
  }

  useEffect(() => {
    setErrors(documentsErrors);
    if (documentsErrors?.length && documentsErrors?.length > 0) {
      if (filesNumber == 1) {
        var error = documentsErrors.find(x => x.document_Code == documentCode);
        if (error)
          setDocumentErrorMessage(error.message)
      } else if (filesNumber == 2) {
        var error = documentsErrors.find(x => x.document_Code == documentCode);
        if (error)
          setDocumentErrorMessage(error.message)
      }
    }
  }, [documentsErrors]);

  const itemTemplate = (file: object, options: ItemTemplateOptions, fileNumber: number): React.ReactNode => {
    const { name } = file as File
    return (
      <>
        <div className="uploaded">
          <p className="file-name">{name}</p>
          {!errors || (errors && errors.length == 0) && <span className="pi pi-check" style={{ color: '#1AE451' }}></span>}
          {errors && errors.length > 0 && errors.find(x => x.document_Code == documentCode)
            && errors.find(x => x.document_Code == documentCode).isOk && <span className="pi pi-check" style={{ color: '#1AE451' }}></span>}
          {errors && errors.length > 0 && errors.find(x => x.document_Code == documentCode)
            && !errors.find(x => x.document_Code == documentCode).isOk
            && <span className="pi pi-exclamation-triangle" style={{ color: '#DC4D57' }}></span>}
          <Button type="button" icon="pi pi-trash" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={(e) => RemoveFile(e, options, fileNumber, name)} />
        </div>
      </>
    );
  };

  return (
    <div className={`${label && label.length > 45 ? 'double-lines' : ''}`}>
      <div className="p-fileupload p-fileupload-advanced p-component" style={{
        borderColor:
          errors && errors.length > 0 && errors.find(x => x.document_Code == documentCode)
            && !errors.find(x => x.document_Code == documentCode).isOk ? 'solid 1px #DC4D57' : 'solid 1px #DC4D57'
      }}>
        <div className="p-fileupload-buttonbar" onClick={clickUploadButton}>
          <span className="p-button p-fileupload-choose p-component" data-pc-section="choosebutton">
            <span className="p-button-label">{label}{required && <span className="required">*</span>}</span>
            {filesNumber == 1 && <span className="pi pi-upload" style={{ color: '#DC4D57' }}></span>}
          </span>
        </div>
        <div className="p-fileupload-content">
          {children}
          <FileUpload
            ref={uploadRef1}
            name="file"
            customUpload
            uploadHandler={(e) => handleFileUpload(e, 1)}
            accept=".pdf, .jpeg, .jpg"
            maxFileSize={2000000}
            chooseOptions={{ className: documentsNumber == 2 ? 'upload-button' : '', icon: documentsNumber == 2 ? 'pi pi-upload' : '' }}
            chooseLabel={documentsNumber == 2 ? 'Fichier recto' : ' '}
            auto
            itemTemplate={(file, options) => itemTemplate(file, options, 1)}
            invalidFileSizeMessageSummary='Le fichier est trop volumineux'
            invalidFileSizeMessageDetail='max 1 Mo'
          />
          {documentsNumber && (
            <>
              {
                documentsNumber == 2 && <div className='mb-3'>
                  <FileUpload
                    ref={uploadRef2}
                    name="file"
                    customUpload
                    uploadHandler={(e) => handleFileUpload(e, 2)}
                    accept=".pdf, .jpeg, .jpg"
                    maxFileSize={10000000}
                    chooseOptions={{ className: documentsNumber == 2 ? 'upload-button' : '', icon: documentsNumber == 2 ? 'pi pi-upload' : '' }}
                    chooseLabel={documentsNumber == 2 ? 'Fichier verso' : ' '}
                    auto
                    itemTemplate={(file, options) => itemTemplate(file, options, 2)}
                    invalidFileSizeMessageSummary='Le fichier est trop volumineux'
                    invalidFileSizeMessageDetail='max 1 Mo'
                  />
                </div>
              }
            </>
          )}
          <span className="info-text" onClick={clickUploadButton}>{infoText}</span>
          {errors && errors.length > 0 && errors.find(x => x.document_Code == documentCode) &&
            <div style={{ paddingLeft: '1rem', color: '#DC4D57', fontSize: 'small', paddingBottom: '1rem' }}>{documentErrorMessage}</div>}
          <div style={{ paddingLeft: '1rem', fontSize: 'small', paddingBottom: '1rem' }}>{documentMessage}</div>
        </div>
      </div>
    </div>
  )
}

export default Upload