import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import StepperLayout from '../layouts/StepperLayout';
import Step1 from '../pages/Step1';
import Step2 from '../pages/Step2';
import Step3 from '../pages/Step3';
import Step4 from '../pages/Step4';
import Step5 from '../pages/Step5';
import Step6 from '../pages/Step6';
import Step7 from '../pages/Step7';
import Step8 from '../pages/Step8';
import Step9 from '../pages/Step9';
import Step10 from '../pages/Step10';
import Step11 from '../pages/Step11';
import Step12 from '../pages/Step12';
import Step13 from '../pages/Step13';
import Step14 from '../pages/Step14';
import OTP from '../pages/OTP';
import ElectronicSignatureStep from '../pages/ElectronicSignatureStep';
import FinalStep from '../pages/FinalStep';
import PaperSignatureStep from '../pages/PaperSignatureStep';
import FAQ from '../pages/FAQ';
import Error from '../pages/Error';

function MainRouter() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/step1')
    }
    window.scrollTo(0, 0)
  }, [location, navigate]);
  return (

    <Routes>
      <Route path="OTP" element={<OTP />} />
      <Route path="/" element={<StepperLayout />}>
        <Route path="step1" element={<Step1 />} />
        <Route path="step2" element={<Step2 />} />
        <Route path="step3" element={<Step3 />} />
        <Route path="step4" element={<Step4 />} />
        <Route path="step5" element={<Step5 />} />
        <Route path="step6" element={<Step6 />} />
        <Route path="step7" element={<Step7 />} />
        <Route path="step8" element={<Step8 />} />
        <Route path="step9" element={<Step9 />} />
        <Route path="step10" element={<Step10 />} />
        <Route path="step11" element={<Step11 />} />
        <Route path="step12" element={<Step12 />} />
      </Route>
      <Route path="/step13" element={<Step13 />} />
      <Route path="/step14" element={<Step14 />} />
      <Route path="/electronicsignaturestep" element={<ElectronicSignatureStep />} />
      <Route path="/PaperSignatureStep" element={<PaperSignatureStep />} />
      <Route path="/finalstep" element={<FinalStep />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<Navigate to={"/step1"} />} />
    </Routes>
  )
}

export default MainRouter