
import Helper from '../components/Helper'
import Upload from '../components/Upload'
import { useEffect, useState } from 'react';
import { useClient } from '../contexts/LivretMainContext';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../Interceptors/axiosconfig';
import { documentsErrors } from '../types/documents';
import Checkbox from '../components/Checkbox';
import { useUpdateClient } from '../hooks/ClientHooks';

function Step12() {
  const UpdateClientMutation = useUpdateClient("/step13");
  const navigate = useNavigate();
  const { client, setClient, setSubStep } = useClient();
  const [num, setNum] = useState<number | undefined>(undefined);
  const [numHost, setNumHost] = useState<number | undefined>(undefined);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValueHost, setSelectedValueHost] = useState("");
  const [canProceed, setCanProceed] = useState(false);
  const [isScanning, SetIsScanning] = useState(false);
  const [nbAttempts, setNbAttempts] = useState(0);
  const [documentsErrors, setDocumentsErrors] = useState<documentsErrors[]>([]);
  const [byPassEasyconforme, setByPassEasyconfrome] = useState(false);
  const [scanningComplete, setScanningComplete] = useState(false);

  const OnSelectChange = (evt: any) => {
    setSelectedValue(evt.target.value);
    if (!evt.target.value) {
      setNum(undefined)
    } else if (evt.target.value.includes('2')) {
      setNum(2)
    } else {
      setNum(1)
    }
  }

  const OnSelectHostChange = (evt: any) => {
    setSelectedValueHost(evt.target.value);
    if (!evt.target.value) {
      setNumHost(undefined)
    } else if (evt.target.value.includes('2')) {
      setNumHost(2)
    } else {
      setNumHost(1)
    }
  }

  useEffect(() => {
    setSubStep(12)
    if (client.lIVRET_CLIENT_DOCUMENTS && client.lIVRET_CLIENT_DOCUMENTS.find(x => x.lcD_DOCUMENT_CODE == "TI-IDENTITE")) {
      setSelectedValue(client.lIVRET_CLIENT_DOCUMENTS.find(x => x.lcD_DOCUMENT_CODE == "TI-IDENTITE")?.lcD_DOCUMENT_TYPE ?? "");
      setNum(1);
    } else if (client.lIVRET_CLIENT_DOCUMENTS && client.lIVRET_CLIENT_DOCUMENTS.find(x => x.lcD_DOCUMENT_CODE == "TI-IDENTITE-R")) {
      setSelectedValue(client.lIVRET_CLIENT_DOCUMENTS.find(x => x.lcD_DOCUMENT_CODE == "TI-IDENTITE-R")?.lcD_DOCUMENT_TYPE ?? "");
      setNum(2);
    }
    if (client.lIVRET_CLIENT_DOCUMENTS && client.lIVRET_CLIENT_DOCUMENTS.find(x => x.lcD_DOCUMENT_CODE == "HE-IDENTITE")) {
      setSelectedValueHost(client.lIVRET_CLIENT_DOCUMENTS.find(x => x.lcD_DOCUMENT_CODE == "HE-IDENTITE")?.lcD_DOCUMENT_TYPE ?? "");
      setNumHost(1);
    } else if (client.lIVRET_CLIENT_DOCUMENTS && client.lIVRET_CLIENT_DOCUMENTS.find(x => x.lcD_DOCUMENT_CODE == "HE-IDENTITE-R")) {
      setSelectedValueHost(client.lIVRET_CLIENT_DOCUMENTS.find(x => x.lcD_DOCUMENT_CODE == "HE-IDENTITE-R")?.lcD_DOCUMENT_TYPE ?? "");
      setNumHost(2);
    }
  }, [setSubStep]);

  useEffect(() => {
    CanProceed();
  }, [canProceed, client])

  useEffect(() => {
    CanProceed();
  }, [])

  const CanProceed = () => {
    if (isScanning == true) {
      setCanProceed(false);
    } else {
      var NbreDocs = client.lIVRET_CLIENT_DOCUMENTS.length;
      if (client.lC_HEBERGEMENT_TIERS) {
        setCanProceed(num == 2 && numHost == 2 ? NbreDocs >= 8 : (((num == 2 && numHost == 1) || (num == 1 && numHost == 2 ))? NbreDocs >= 7 : NbreDocs >= 6));
      } else {
        setCanProceed(num == 2 ? NbreDocs >= 5 : NbreDocs >= 4);
      }
    }
  }

  const OnNextClick = (url: string) => {
    if (scanningComplete || byPassEasyconforme) {
      navigate(url);
    } else {
      setDocumentsErrors([]);
      SetIsScanning(true);
      setCanProceed(false);

      var docIds: string[] = [];
      for (var i = 0; i < client.lIVRET_CLIENT_DOCUMENTS.length; i++) {
        docIds.push(getFilenameWithoutExtension(client.lIVRET_CLIENT_DOCUMENTS[i].lcD_REF_ADG));
      }
      CreateAnalyseFile(docIds);
    }
  }

  const CreateAnalyseFile = (docIds: string[]) => {
    axiosInstance.post(`/api/FileUpload/CreateAnalyzeFolder?clientGuid=${client.lC_GUID}`, docIds).then((resp) => {
      if (client.lC_JUMP_EASYCONFORM_STEP) {
        UpdateClientMutation.mutate(client)
      } else {
        var docErrrors: documentsErrors[] = []
        var responses = resp.data;
        for (var i = 0; i < responses.length; i++) {
          var data = responses[i];
          var doc: documentsErrors = {
            clientGuid: data.clientGuid,
            document_Code: data.document_Code,
            document_Identifier: data.document_Identifier,
            document_Type: data.document_Type,
            isOk: data.isOk == "1" ? true : false,
            message: data.message,
            ref_Adg: data.ref_Adg
          }
          docErrrors.push(doc);
        }
        //Incremente le nombre de tentatives si au moins l'un des documents n'est pas valide
        //set scanning complete dans le cas contraire pour pouvoir passer  à l'étape suivante
        if (docErrrors.length > 0 && docErrrors.find(x => !x.isOk))
          setNbAttempts(nbAttempts + 1);
        else
          setScanningComplete(true);

        setDocumentsErrors(docErrrors)
        SetIsScanning(false);
        setCanProceed(true);
        if (nbAttempts + 1 >= 6 && docErrrors.length > 0 && docErrrors.find(x => x.document_Code == "TI-IBAN") && !docErrrors.find(x => x.document_Code == "TI-IBAN")?.isOk) {
          //bloque le client de pass easyconfrome si nombre de tentatives >6 et le rib n'est pas valide
          setCanProceed(false);
          setByPassEasyconfrome(false);
        } else if (nbAttempts + 1 >= 6) {
          //by pass easyconfrome si nombre de tentatives >6 et au moins le rib est valide  
          setByPassEasyconfrome(true);
          setCanProceed(true);
        }
      }
    })
  }

  useEffect(() => {
    setDocumentsErrors(documentsErrors)
  }, [documentsErrors])

  const getFilenameWithoutExtension = (idAdg: string) => {
    const lastDotIndex = idAdg.lastIndexOf('.');
    if (lastDotIndex == -1)
      return idAdg;
    return idAdg.substring(0, lastDotIndex);
  }

  return (
    <>
      <div className="main">
        <div className="form">
          <h2>Vous y êtes presque&nbsp;!</h2>
          <div className='mb-4'>
            <Helper>
              <p>Les pièces justificatives sont obligatoires pour l'ouverture de votre livret.</p>
              <p>Elles doivent être au format : PDF, JPEG, JPG et le poids maximal d'un fichier est de 10 Mo.</p>
              <p>Vous pouvez consulter <a href="PAD.mp4" target="_blank" rel="noreferrer">cette vidéo</a> qui présente les bonnes pratiques pour télécharger vos pièces justificatives correctement.</p>
            </Helper>
          </div>
          <div className="form-content">
            <Upload
              label={`Justificatif d'identité`}              
              infoText="Carte d'identité, passeport, titre de séjour"
              required={true}
              documentsNumber={num}
              documentCode="TI-IDENTITE"
              documents={client.lIVRET_CLIENT_DOCUMENTS}
              documentsErrors={documentsErrors}
              documentType={selectedValue}>
              <div className='form-group-select mb-3 mt-3'>
                <select className="f_select" style={{ borderColor: '#0063BE', cursor: 'pointer' }} value={selectedValue} onChange={OnSelectChange}>
                  <option value=""></option>
                  <option value="CNI">Carte d'identité - Recto et Verso</option>
                  <option value="CNI2">Carte d'identité - Recto et Verso - 2 documents</option>
                  <option value="PASSEPORT">Passeport</option>
                  <option value="TDS">Titre de séjour - Recto et Verso</option>
                  <option value="TDS2">Titre de séjour - Recto et Verso - 2 documents</option>
                </select>
              </div>
            </Upload>
            {
              client.lC_HEBERGEMENT_TIERS ? <Upload
                label={`Attestation d'hébergement`}
                infoText="Attestation d'hébergement signée par votre hébergeur"
                required={true}
                documentsNumber={1}
                documentCode="TI-DOMICILE"
                documents={client.lIVRET_CLIENT_DOCUMENTS}
                documentsErrors={documentsErrors}>
                <></></Upload> :
                <Upload
                  label={`Justificatif de domicile`}
                  infoText="Facture de gaz ou d'électricité, facture d'accès à internet, facture de votre téléphone fixe"
                  required={true}
                  documentsNumber={1}
                  documentCode="TI-DOMICILE"
                  documents={client.lIVRET_CLIENT_DOCUMENTS}
                  documentsErrors={documentsErrors}>
                  <></></Upload>
            }
            <Upload
              label="Relevé d'identité bancaire"
              infoText="Ce RIB de votre banque actuelle doit être à votre nom. Votre premier virement devra provenir de ce compte"
              required={true}
              documentsNumber={1}
              documentCode="TI-IBAN"
              documents={client.lIVRET_CLIENT_DOCUMENTS}
              documentsErrors={documentsErrors}>
              <></></Upload>
            <Upload
              label="Justificatif de revenus"
              infoText="Avis d’imposition, bulletin de salaire"
              required={true}
              documentsNumber={1}
              documentCode="TI-REVENUS"
              documents={client.lIVRET_CLIENT_DOCUMENTS}
              documentsErrors={documentsErrors}>
              <></></Upload>
            {
              client.lC_HEBERGEMENT_TIERS ?
                <>
                  <Upload
                    label={`Justificatif d'identité de votre hébergeur`}
                    infoText="carte d'identité, passeport, carte de séjour"
                    required={true}
                    documentsNumber={numHost}
                    documentCode="HE-IDENTITE"
                    documents={client.lIVRET_CLIENT_DOCUMENTS}
                    documentsErrors={documentsErrors}
                    documentType={selectedValueHost}>
                    <div className='form-group-select mb-3 mt-3'>
                      <select className="f_select" style={{ borderColor: '#0063BE', cursor: 'pointer' }} value={selectedValueHost} onChange={OnSelectHostChange}>
                        <option value=""></option>
                        <option value="CNI">Carte d'identité - Recto et Verso</option>
                        <option value="CNI2">Carte d'identité - Recto et Verso - 2 documents</option>
                        <option value="PASSEPORT">Passeport</option>
                        <option value="TDS">Titre de séjour - Recto et Verso</option>
                        <option value="TDS2">Titre de séjour - Recto et Verso - 2 documents</option>
                      </select>
                    </div>
                  </Upload>
                  <Upload
                    label={`Justificatif de domicile${client.lC_HEBERGEMENT_TIERS ? ' de votre hébergeur' : ''}`}
                    infoText="Quittance de loyer, facture gaz ou éléctricité"
                    required={true}
                    documentsNumber={1}
                    documentCode="HE-DOMICILE"
                    documents={client.lIVRET_CLIENT_DOCUMENTS}>
                    <></></Upload>
                </> : ''
            }
            {isScanning && <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <span style={{ color: "white" }}>Analyse en cours...</span>
              <div className="ring"></div>
            </div>}
          </div>
          <Checkbox
            name="Jump_Easyconform_Step"
            onChange={(e) => setClient({ ...client, lC_JUMP_EASYCONFORM_STEP: e.target.checked })}
            checked={client.lC_JUMP_EASYCONFORM_STEP}
            text="Sauter l'étape Easyconforme sur les tentatives et le controle du RIB"
          />
          <div className='text-white mt-6'><span className="required">*</span> Document obligatoire </div>
        </div >
        <div>
        </div>
      </div >
      <div className="helper-container">
      </div>
      <div className="navigation-container">
        <div className={`content`}>
          <Button type="prev" onClick={() => navigate("/step11")}>Précédent</Button>
          {!canProceed ? (
            <Button type="next" disabled={!canProceed}>Suivant</Button>) :
            (<Button type="next" disabled={!canProceed} onClick={() => OnNextClick("/step13")}>Suivant</Button>)}
        </div>
      </div>
    </>
  )
}

export default Step12
