import Input from "../components/Input";
import Button from "../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { SendOtpRequestDTO, SendOtpResponseDTO, ValidateOtpRequestDTO, ValidateOtpResponseDTO } from "../types/otp";
import { useClient } from '../contexts/LivretMainContext';
import axiosInstance from '../Interceptors/axiosconfig';
import Loader from '../components/Loader';
import { convertSecondsTOMinutes } from '../utils/dateTime';
import errorIcon from "../assets/icons/Error.svg";
import { isNullOrWhiteSpace, validateNumberOnly } from "../utils/string";

const OTP = () => {
    const nav = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    if (!token) throw Error("Need token");

    const [maxReached, setMaxReached] = useState(false);
    const [timer, setTimer] = useState<number | undefined>(undefined);
    const [mainError, setMainError] = useState("");
    const { setSubStep } = useClient();
    const [OtpCode, setOtpCode] = useState('');
    const [clientPhoneNumber, setClientPhoneNumber] = useState<string | undefined>(undefined);
    const { client, setClient } = useClient();
    const [error, setError] = useState<boolean>(false);
    const [canProceed, setCanProceed] = useState(false);
    const [otpId, setOtpId] = useState<string | undefined>();


    const handleSendOTP = async () => {
        try {
            const getOtpDTO: SendOtpRequestDTO = {
                token: token
            };
            const response = await axiosInstance.post<SendOtpResponseDTO>(`/api/OTP`, getOtpDTO);
            setClientPhoneNumber(response.data.phoneNumber);
            setError(false);
        }
        catch
        {
            setError(true);
        }
    };


    const handleValidateOTP = async () => {
        try {
            setError(false);
            const validateOtpDTO: ValidateOtpRequestDTO = {
                token: token,
                codeOTP: OtpCode

            }
            const response = await axiosInstance.post<ValidateOtpResponseDTO>(`/api/OTP/ValidateOTP`, validateOtpDTO);
            setOtpId(response.data.token);
            if (response.data.isValid) {
                setClient({
                    ...client,
                    lC_GUID: response.data.client.lC_GUID,
                    lC_EMAIL: response.data.client.lC_EMAIL,
                    lC_NOM: response.data.client.lC_NOM,
                    lC_AUTRE_NOM: response.data.client.lC_AUTRE_NOM,
                    lC_PRENOM: response.data.client.lC_PRENOM,
                    lC_DATE_NAISSANCE: response.data.client.lC_DATE_NAISSANCE,
                    lC_COORD_ADR1: response.data.client.lC_COORD_ADR1,
                    lC_CP_NAISSANCE: response.data.client.lC_CP_NAISSANCE,
                    lC_CLIENT_MAJEUR_CAPABLE: true,
                    lC_DETENTEUR_LIVRET_BFM: false,
                    lC_VILLE_NAISSANCE: response.data.client.lC_VILLE_NAISSANCE,
                    lC_PAYS_NAISSANCE: response.data.client.lC_PAYS_NAISSANCE,
                    lC_CONFIRMATION_EMAIL: response.data.client.lC_CONFIRMATION_EMAIL,
                    lC_TELEPHONE: response.data.client.lC_TELEPHONE,
                    lC_SITUATION_FAM: response.data.client.lC_SITUATION_FAM,
                    lC_PATRIMOINE_FINANCIER: response.data.client.lC_PATRIMOINE_FINANCIER,
                    lC_PATRIMOINE_IMMOBILIER: response.data.client.lC_PATRIMOINE_IMMOBILIER,
                    lC_REVENUE_MENSUEL: response.data.client.lC_REVENUE_MENSUEL,
                    lC_CIVILITE: response.data.client.lC_CIVILITE,
                    lC_STATUT: response.data.client.lC_STATUT,
                    lC_NATIONALITE: response.data.client.lC_NATIONALITE,
                    lC_CLIENT_SG: response.data.client.lC_CLIENT_SG,
                    lC_COORD_ADR2: response.data.client.lC_COORD_ADR2,
                    lC_COORD_CP: response.data.client.lC_COORD_CP,
                    lC_COORD_VILLE: response.data.client.lC_COORD_VILLE,
                    lC_COORD_PAYS: response.data.client.lC_COORD_PAYS,
                    lC_COMMUNIC_COMM_OK: response.data.client.lC_COMMUNIC_COMM_OK,
                    lC_HEBERGEMENT_TIERS: response.data.client.lC_HEBERGEMENT_TIERS,
                    lC_EST_RESD_FISCALE_FRANCE: response.data.client.lC_EST_RESD_FISCALE_FRANCE,
                    lC_EST_RESD_FISCALE_AUTRES: response.data.client.lC_EST_RESD_FISCALE_AUTRES,
                    lIVRET_AUTRE_RESID_FISCALES: response.data.client.livreT_AUTRE_RESID_FISCALES,
                    lC_EST_CITOYEN_USA: response.data.client.lC_EST_CITOYEN_USA,
                    lC_EST_PPE: response.data.client.lC_EST_PPE,
                    lC_EST_RELATION_PPE: response.data.client.lC_EST_RELATION_PPE,
                    lC_ROLE_IMPORTANT_DERNIERS_MOIS: response.data.client.lC_ROLE_IMPORTANT_DERNIERS_MOIS,
                    lC_INTITULE_POSTE: response.data.client.lC_INTITULE_POSTE,
                    lC_PROFESSION: response.data.client.lC_PROFESSION,
                    lC_SITUATION_PRO: response.data.client.lC_SITUATION_PRO,
                    lC_CSP_CODE: response.data.client.lC_CSP_CODE,
                    lC_CSP_LIBELLE: response.data.client.lC_CSP_LIBELLE,
                    lIVRET_CLIENT_PPE: response.data.client.lC_EST_PPE ? {
                        lC_PPE_FONCTION: response.data.client.livreT_CLIENT_PPE.lC_PPE_FONCTION,
                        lC_PPE_ORGANISME: response.data.client.livreT_CLIENT_PPE.lC_PPE_ORGANISME,
                        lC_PPE_DATE_DEBUT: response.data.client.livreT_CLIENT_PPE.lC_PPE_DATE_DEBUT,
                        lC_PPE_DATE_FIN: response.data.client.livreT_CLIENT_PPE.lC_PPE_DATE_FIN,
                        lC_PPE_PAYS: response.data.client.livreT_CLIENT_PPE.lC_PPE_PAYS,
                    } : null,
                    rELATION_PPE: response.data.client.lC_EST_RELATION_PPE ? {
                        rPPE_NOM: response.data.client.relatioN_PPE.rppE_NOM,
                        rPPE_PRENOM: response.data.client.relatioN_PPE.rppE_PRENOM,
                        rPPE_DATE_NAISSANCE: response.data.client.relatioN_PPE.rppE_DATE_NAISSANCE,
                        rPPE_FONCTION: response.data.client.relatioN_PPE.rppE_FONCTION,
                        rPPE_ORGANISME: response.data.client.relatioN_PPE.rppE_ORGANISME,
                        rPPE_DATE_DEBUT: response.data.client.relatioN_PPE.rppE_DATE_DEBUT,
                        rPPE_DATE_FIN: response.data.client.relatioN_PPE.rppE_DATE_FIN,
                        rPPE_PAYS: response.data.client.relatioN_PPE.rppE_PAYS,
                        rPPE_NATURE_RELATION: response.data.client.relatioN_PPE.rppE_NATURE_RELATION
                    } : null,
                    lC_PPREMIER_VERSEMENT: response.data.client.lC_PPREMIER_VERSEMENT,
                    lC_JUMP_EASYCONFORM_STEP: response.data.client.lC_JUMP_EASYCONFORM_STEP,
                    lIVRET_CLIENT_ORIGINES_FONDS: response.data.client.livreT_CLIENT_ORIGINES_FONDS,
                    lIVRET_CLIENT_DOCUMENTS: response.data.client.livreT_CLIENT_DOCUMENTS,
                    lC_STEP: response.data.client.lC_STEP

                });

                setMainError("");
                nav(`/step${response.data.client.lC_STEP.toString()}`);
            }
            else {

                setMainError("Le code SMS est invalide. Veuillez réessayer.");

                if (response.data.isMaxAttemptsReached === true) {
                    setMaxReached(true);
                    setCanProceed(false);
                    setTimer(response.data.reaminingTime);
                    setMainError(`Nombre de tentatives atteint. Veuillez réessayer dans ${convertSecondsTOMinutes(response.data.reaminingTime)}`);
                }
            }
        }
        catch
        {
            setError(true);
        }
    }

    const fetchTimer = async () => {
        try {
            const response = await axiosInstance.get(`/Timer/GetTimer?token=${otpId}`);
            setTimer(response.data);
            setMainError(`Nombre de tentatives atteint. Veuillez réessayer dans ${convertSecondsTOMinutes(response.data)}`);
            setError(false);
            if (response.data == 0) {
                setMaxReached(false);
                setCanProceed(true);
                setMainError("");
            }
        } catch (error) {
            setError(true);
        }
    };

    const handleOnChangeOTP = async (otpCode: string) => {

        setOtpCode(otpCode);

        setMainError("");
        setCanProceed(true);

        if (isNullOrWhiteSpace(otpCode)) {
            setCanProceed(false);
        }
        else if (!isNullOrWhiteSpace(otpCode) && !validateNumberOnly(otpCode)) {
            setMainError("Le code SMS est invalide. Veuillez réessayer.");
            setCanProceed(false);
        }
    }

    useEffect(() => {
        setSubStep(50);
    }, [setSubStep]);

    useEffect(() => {
        handleSendOTP();
    }, []);

    useEffect(() => {

        if (maxReached === true) {
            const interval = setInterval(fetchTimer, 1000);

            return () => clearInterval(interval);
        }
    }, [maxReached]);


    useEffect(() => {
        if (timer !== undefined) {
            setMainError(`Nombre de tentatives atteint. Veuillez réessayer dans ${convertSecondsTOMinutes(timer)}`)
            if (timer == 0) {
                setMaxReached(false);
                setCanProceed(true);
                setMainError("");
            }
        }
    }, [timer]);

    return clientPhoneNumber ? (
        <>
            <div className="main">
                <div className="otp-container">
                    <h2>Vérification de votre identité</h2>
                    <p className="text-white">Afin de garantir la sécurité de votre demande, merci de saisir le code que vous venez ou allez recevoir par sms au numéro {clientPhoneNumber}</p>

                    <Input
                        name="OTP"
                        label="Code d'activation reçu par SMS"
                        error={mainError || !OtpCode ? true : false}
                        errorMsg={mainError}
                        valid={mainError || !OtpCode ? false : true}
                        onCopy={true}
                        onPaste={true}
                        onChange={(e) => handleOnChangeOTP(e.target.value)}
                        value={OtpCode}
                    />

                    <div className='mt-8'>
                        <Button type="primary-center" onClick={handleValidateOTP} disabled={!canProceed}>Valider</Button>
                        <Button type="secondary-center" onClick={handleSendOTP} disabled={maxReached}>Renvoyer le code par SMS</Button>
                        <Button type="secondary-center" onClick={() => nav('/')}>Revenir à la page de présentation du livret</Button>
                    </div>

                </div>
            </div>
        </>
    ) : error ? <div className="final-container">
        <div className="content">
            <div style={{ textAlign: "center" }}>
                <p>Une erreur inconnue est survenue. Veuillez réessayer ultérieurement</p>
                <img src={errorIcon} style={{ width: "40%", height: "600px" }} alt="Error" title="Error" className="Error" />
            </div>
        </div>
    </div> : <Loader></Loader>;
};

export default OTP;